.main {
  display: grid;
  grid-gap: 2rem;
}

.div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 5px;
}

.container {
  color: #fff;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.containerHistory {
  color: #fff;
  height: 200px;
}

.gridHistory {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.06);
  padding: 0 20px 6px 0;
  margin: 24px 0;
}

.title {
  color: #6b7280;
  font-size: 14px;
  background: #ffff;
  height: 100%;
  padding: 1.25rem 1.5rem 0 1.5rem;
}
.titleBody {
  color: #6b7280;
  margin: 2rem 0 1rem 0;
}
.typographyTotal {
  font-size: 2rem;
  font-weight: bold;
  color: #374151;
}
.typographyUser {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  color: #374151;
}
.typographyNewUser {
  font-size: 0.75rem;
  color: #9ca3af;
}
.typographyTotalUser {
  font-size: 0.7rem;
  color: #9ca3af;
}

.typographyValue {
  margin: 0.5rem 0 0.5rem 0;
  color: #374151;
  font-size: 18px;
  font-weight: 200;
}

.grupo {
  display: flex;
  gap: 14.8rem;
}

.cardUser {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 0.75rem 1.2rem;
}

.filters {
  height: 5rem;
}

.helpTooltip {
  background: rgba(113, 42, 217, 0.8);
  color: #eeeeee;
  font-size: 0.75rem;
  width: 9rem;
}

.helpTooltipArrow {
  color: #712ad9;
  opacity: 0.8;
}

.profitIndicator {
  height: 1.5rem;
  width: 3.9rem;
  align-items: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  padding-right: 0.5rem;

}

.positiveProfitIndicator {
  background-color: #d1fae5;
  color: #065f46;
}

.negativeProfitIndicator {
  background-color: #fee2e2;
  color: #991b1b;
}

.circle {
  strokelinecap: 'round';
}
